.taxpayer-button {
  width: 200px;
  font-size: 14px;
  background-color: white;
  color: black;
  border: 1px solid black;
  height: 50px;
  border-radius: 6px;
  font-weight: 700;
  margin-top: 16px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  transition: filter 0.2s;
  cursor: pointer;
}

.taxpayer-button:hover {
  filter: brightness(90%);
}

.taxpayer-errors h3 {
  padding: 0;
  margin: 0;
}

.taxpayer-errors p {
  padding: 0;
  margin: 0;
}

.popup-download-dam {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
