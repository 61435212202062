.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5.00);
  display: flex;
  height: 100%;
  width: 100%;
}

.txtNumerico input{
  text-align: right;
  padding-right: 5px;
}