.my-card {
  width: 100%;
  padding: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-box {
  width: 100%;
  max-width: 1000px;
  background-color: white;
  display: inline-block;
  border: 1px solid rgb(185, 182, 182);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
  padding: 40px;
  border-radius: 6px;
}

.button {
  width: 100%;
  height: 50px;
  background: #ff5722;
  border: 0;
  border-radius: 6px;
  color: #fff;
  font-weight: 700;
  margin-top: 16px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 50px;
  transition: filter 0.2s;
  cursor: pointer;
}

.button:hover {
  filter: brightness(90%);
}

.back-link {
  display: flex;
  align-items: center;
  color: #41414d;
  font-size: 18px;
  text-decoration: none;
  transition: opacity 0.2s;
}

.back-link svg {
  margin-right: 8px;
}

.back-link:hover {
  opacity: 80%;
}

@media (min-width: 5px) and (max-width: 900px) {
  .captcha {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 20px 0px;
  }

  .grouping-items {
    display: inline-block;
    justify-content: center;
    padding-bottom: 26px;
  }
}

@media (min-width: 900px) {
  .captcha {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 20px;
  }

  .formPopup {
    width: 100%;
    height: 80vh;
    padding: 96px;
    justify-content: center;
    align-items: center;
  }

  .formPopup button {
    margin-top: 100px;
  }

  .grouping-items {
    display: flex;
    padding-bottom: 26px;
  }

  .grouping-items .button + .button {
    margin-left: 60px;
  }
}

.my-card .input-box h2 {
  text-align: center;
  color: rgba(117, 117, 117, 0.87);
  text-shadow: 0 2 2px white;
}

.my-card .input-box p {
  color: rgba(117, 117, 117, 0.87);
}
