.home-container {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.services-header {
  width: 100%;
  margin-bottom: 80px;
  margin-top: 20px;
}

.services-header h3 {
  font-weight: 700;
  font-size: 30px;
}

.card-menu-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 80px;
}

.item-box {
  margin-bottom: 30px;
  background-color: #ff5722;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  color: #FFF !important;
  width: 300px;
  height: 110px;
  padding: 36px 26px;
  border-radius: 20px;
  word-wrap: break-word;
  align-items: center;
  transition: 0.2s;
  display: flex;
}

.item-box:hover {
  opacity: 0.8;
}

.item-box svg {
  margin-right: 10px;
}